<template>
   <div>
      <!-- Cart widget -->
      <div class="mb-2">
      <h4 class="sidebar-title mb-3"> Your Cart </h4>
      </div>
      <div class="d-flex justify-content-between mb-2 border-bottom py-3" v-for="(cartInfo,i) of cart.data" :key="i">
         <div class="d-flex justify-content-start">
            <div class="media">
               <img :src="cartInfo.image" alt="product images" class="img-fluid border d-flex mr-3" width="80" height="80" />
               <div class="media-body">
                  <h5 class="fw-700 mb-1"> {{cartInfo.heading}}</h5>
                  <p class="m-0">{{cartInfo.sub_title}}</p>
                  <p class="m-0 text-primary">$ {{cartInfo.price}}</p>
               </div>
            </div>
         </div>
         <div class="d-flex align-items-start">
            <button type="button" class="close text-danger font-2x" data-dismiss="alert" aria-label="Close">
               <span aria-hidden="true"><i class="ion-ios-close"></i></span>
            </button>
         </div>
      </div>
      <div class="d-flex justify-content-between py-3">
      <span class="d-flex justify-content-start font-26"> Total : $216 </span>
      <span class="d-flex justify-content-end"><a href="javascript:void(0)" class="btn btn-dark">Checkout</a></span>
      </div>
   </div>
</template>
<script>
   import cart from 'Components/data/cart.json'
   export default{
      data(){
         return{
            cart
         }         
      }
   }
</script>